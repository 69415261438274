import { render, staticRenderFns } from "./sliderPart.vue?vue&type=template&id=d2d52626&scoped=true"
import script from "./sliderPart.vue?vue&type=script&lang=js"
export * from "./sliderPart.vue?vue&type=script&lang=js"
import style0 from "./sliderPart.vue?vue&type=style&index=0&id=d2d52626&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+com_babb32925cd4b8a8c1892c895ee75fdf/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2d52626",
  null
  
)

export default component.exports